/* Variables */
const style = document.createElement('style');
const img1 = require("url:../assets/lab1.png")
const img2 = require("url:../assets/lab2.png")
const btn_email = require("url:../assets/btn-email.png")
const btn_whatsapp = require("url:../assets/btn-whatsapp.png")
const btn_horarios = require("url:../assets/btn-horarios.png")

/* Class Laboratorio */
export class LaboratorioPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()
    };

    /* Render */
    render(){
        this.className = 'laboratorio-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="laboratorio-container">
            <!-- Body -->
            <div class="laboratorio-body">
                <div class="body-box">
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <br>
                            <h3 class="body__cuerpo-text-title">Laboratorio de Análisis Clínicos y Microbiológicos</h3>
                            <br>
                            <h5 class="body__cuerpo-text-subtitle"> La Dirección del Laboratorio tiene como objetivo la calidad, innovación tecnológica y la mejora continua, poniendo énfasis en la satisfacción y seguridad de nuestros pacientes y médicos; mediante la entrega de resultados confiables y reproducibles, con un tiempo de respuesta adecuado a los compromisos establecidos y de acuerdo al uso previsto para diagnóstico, pronóstico y seguimiento clínico.<br> El plantel del Laboratorio cuenta con destacados profesionales comprometidos con la capacitación continua.<br> En el mes de enero de 2023, el Laboratorio obtuvo su acreditación del programa de evaluación externa de calidad de la fundación bioquímica argentina.<br> Cumplir con esta evaluación vigente implica que el Laboratorio tiene implementado un sistema de Gestión de la Calidad en mejora continua, reconociendo su competencia técnica para la realización de los análisis acreditados.</p>• Dirección Técnica:<br>Dra. Liliana Bertola - M.P. 3.585</p>• Bioquimicos:<br>Dra. Gisel Bersi - M.P. 6.343<br>Dra. María Julia Armonti - M.P. 4.767<br>Dra. Flavia Máspero - M.P. 6.656</p>• Tec. Laboratorio:<br>Avila Daniela<br>Emiliozzi Liliana<br>Ortiz Karina</p></h5>
                            <h5 class="body__cuerpo-text-subtitle"> • Conctacto: </h5>
                            <div class="body__cuerpo-contacto">
                                <a class="" href="/horarios"><img class="btn" src="${btn_horarios}"></a>
                                <a class="" href="https://api.whatsapp.com/send?phone=543585610243&text&source&data&app_absent&lang=es"><img class="btn" src="${btn_whatsapp}"></a>
                                <a class="" href="mailto:laboratorio@sanatorioprivado.com.ar"><img class="btn" src="${btn_email}"></a>
                            </div>
                        </div>
                        <div class="body__cuerpo-text">
                            <h5 class="body__cuerpo-text-subtitle">Preparación para Estudios</h5>
                            <div class="body__cuerpo-box">
                                <a class="link" href="#">Curva de Glucosa</a>
                                <a class="link" href="#">Recolección de orina de 24Hs para dosar calciura</a>
                                <a class="link" href="#">Extracción de Serotonina</a>
                                <a class="link" href="#">Clearence de Creatinina</a>
                                <a class="link" href="#">Examen Coparasitologico</a>
                                <a class="link" href="#">Cortisol Salival</a>
                                <a class="link" href="#">Extracción de Prolactina</a>
                            </div>
                            <div class="body__cuerpo-box">
                                <img src="${img1}" class="body__cuerpo-fotos">
                                <img src="${img2}" class="body__cuerpo-fotos">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .laboratorio-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .laboratorio-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 100px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
        }
        .body__cuerpo-text{
            min-height: 200px;
            width: 90%;
        }
        .body__cuerpo-text-title{
            margin: 10px auto;
            font-size: 25px;
            text-align: start;
        }
        .body__cuerpo-text-subtitle{
            margin: 10px auto;
            font-size: 15px;
        }
        .body__cuerpo-img-a{
            height: 400px;
            width: 400px;
            background-color: #1E1E1E75;
        }
        .link{
            text-decoration: none;
            font-size: 15px;
            color: whitesmoke;
            background: #50af70;
            padding: 10px;
            border-radius: 5px;
        }
        .btn{
            width: 150px;
        }
        .body__cuerpo-box{
            display: grid;
            grid-template-columns: 45% 45%;
            gap: 10px;
            padding: 10px;
            justify-content: center;
        }
        .body__cuerpo-fotos{
            width: 100%;
        }
        .body__cuerpo-contacto{
            display: grid;
            grid-template-columns: 30% 30% 30%;
            justify-content: space-evenly;
            justify-items: center;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("laboratorio-page", LaboratorioPage)