export const data = [
    {
        Mutual: "ABOGADOS"
    },
    {
        Mutual: "AMPROSA"
    },
    {
        Mutual: "AMSTERDAM SALUD"
    },
    {
        Mutual: "APROSS"
    },
    {
        Mutual: "ASOCIART SA ART"
    },
    {
        Mutual: "ASPURC"
    },
    {
        Mutual: "ATSA - OSPSA"
    },
    {
        Mutual: "AVALIAN"
    },
    {
        Mutual: "BERKLEY ART"
    },
    {
        Mutual: "CAJA NOTARIAL"
    },
    {
        Mutual: "CARMED"
    },
    {
        Mutual: "CIENCIAS ECONOMICAS"
    },
    {
        Mutual: "ENDOCTER - ENSALUD"
    },
    {
        Mutual: "ENSALUD"
    },
    {
        Mutual: "EXPERTA ART (EX QBE)"
    },
    {
        Mutual: "FEDERADA SALUD"
    },
    {
        Mutual: "FUTBOLISTAS AGREMIADOS"
    },
    {
        Mutual: "GALENO - AZUL Y BLANCO"
    },
    {
        Mutual: "GALENO - ORO Y PLATA"
    },
    {
        Mutual: "GENMED MEDICAL ASISTANCE"
    },
    {
        Mutual: "GRAFICOS"
    },
    {
        Mutual: "IOSFA - DIBA"
    },
    {
        Mutual: "ISSPICA"
    },
    {
        Mutual: "JERARQUICO SALUD"
    },
    {
        Mutual: "LA SEGUNDA ART "
    },
    {
        Mutual: "LABOUMED SRL"
    },
    {
        Mutual: "LUIS PASTEURS"
    },
    {
        Mutual: "LUZ Y FUERZA (AESA) ALUMBRAR"
    },
    {
        Mutual: "LUZ Y FUERZA REGIONAL SALUD"
    },
    {
        Mutual: "MADEREROS"
    },
    {
        Mutual: "MEDICUS - AZUL - BLANCO - CELESTE"
    },
    {
        Mutual: "MEDICUS - CORP - FAMILY - MC"
    },
    {
        Mutual: "MEDINALS - PREMED"
    },
    {
        Mutual: "MUNICIPALES"
    },
    {
        Mutual: "MUTUAL MEDICA"
    },
    {
        Mutual: "NOBIS"
    },
    {
        Mutual: "OMINT SA"
    },
    {
        Mutual: "OPDEA "
    },
    {
        Mutual: "OSADEF (EMPLEADOS DE FARMACIA)"
    },
    {
        Mutual: "OSAM - MINEROS"
    },
    {
        Mutual: "OSAPM  (PROPAGANDA MEDICA)"
    },
    {
        Mutual: "OSDE"
    },
    {
        Mutual: "OSDOP - SERVIRED"
    },
    {
        Mutual: "OSFATLYF RIO CUARTO"
    },
    {
        Mutual: "OSFATLYF VILLA MARIA"
    },
    {
        Mutual: "OSFATUN NO DOCENTE"
    },
    {
        Mutual: "OSOETSYLRA - TINTOREROS (ENSALUD)"
    },
    {
        Mutual: "OSPACA"
    },
    {
        Mutual: "OSPCRA (ENSALUD)"
    },
    {
        Mutual: "OSPECOR"
    },
    {
        Mutual: "OSPEDYC"
    },
    {
        Mutual: "OSPEP (PANADEROS)"
    },
    {
        Mutual: "OSPERHYRA"
    },
    {
        Mutual: "OSPES (ESTACIONES DE SERVICIO)"
    },
    {
        Mutual: "OSPIHMP (HIELO) ENSALUD"
    },
    {
        Mutual: "OSPIM (ENSALUD)"
    },
    {
        Mutual: "OSSACRA"
    },
    {
        Mutual: "OSTEL - ACTIVOS - ENSALUD"
    },
    {
        Mutual: "OSTEL - JUBILADOS - ENSALUD"
    },
    {
        Mutual: "PARQUE SALUD"
    },
    {
        Mutual: "PASTELEROS (OPCPHYA)"
    },
    {
        Mutual: "PODER JUDICIAL"
    },
    {
        Mutual: "POLICIA FEDERAL"
    },
    {
        Mutual: "PRENSA"
    },
    {
        Mutual: "PREVENCION SALUD"
    },
    {
        Mutual: "PRIVEL OBRA SOCIAL"
    },
    {
        Mutual: "PRIVEL SERVICIOS SOCIALES"
    },
    {
        Mutual: "SADAIC"
    },
    {
        Mutual: "SAN PEDRO"
    },
    {
        Mutual: "SANCOR  SALUD"
    },
    {
        Mutual: "SAT (TV SALUD)"
    },
    {
        Mutual: "SEGUROS"
    },
    {
        Mutual: "SERVIRED (OSBLYCA)"
    },
    {
        Mutual: "SWISS MEDICAL"
    },
    {
        Mutual: "UNIMED"
    },
    {
        Mutual: "UTA"
    }
]