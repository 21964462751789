/* Variables */
const style = document.createElement('style');

/* Class Horarios */
export class HorariosPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()
    };

    /* Render */
    render(){
        this.className = 'horarios-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="horarios-container">
            <!-- Body -->
            <div class="horarios-body">
                <div class="body-box">
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text up">
                            <h3 class="body__cuerpo-text-title title">Horarios de atención, visita y acompañantes</h3>
                        </div>
                    </div>

                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title"> • Guardia y Emergencias</h3>
                            <h4 class="body__cuerpo-text-subtitle">Para la atención por Guardia Médica traer Documento de Identidad y Credencial de la Cobertura Médica.<br> Ingreso por Sargento Cabral 1064</h4>
                        </div>
                    </div>

                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title"> • Horarios de Atención</h3>
                            <h4 class="body__cuerpo-text-title">Recepción / Administración</h4>
                            <h5 class="body__cuerpo-text-subtitle">Lunes a Viernes de 8:00 a 12:00 hs  – 16:00 a 20:00 hs.</h5>
                        </div>
                    </div>

                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title"> • Diagnóstico por Imágenes</h3>
                            <h4 class="body__cuerpo-text-title">Horarios de atención</h4>
                            <h5 class="body__cuerpo-text-subtitle">Lunes a Viernes de 8:00 a 20:00 hs.</h5>
                        </div>
                    </div>

                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title"> • Laboratorio de Análisis Clínicos</h3>
                            <h4 class="body__cuerpo-text-title">Horarios de atención</h4>
                            <h5 class="body__cuerpo-text-subtitle">Lunes a Viernes de 7:30 a 12:00 hs – 16:00 a 20:00 hs.</h5>
                            <h4 class="body__cuerpo-text-title">Horarios de extracción</h4>
                            <h5 class="body__cuerpo-text-subtitle">Lunes a Viernes de 8:00 a 20:00 hs.</h5>
                            <h4 class="body__cuerpo-text-title">Horarios de entrega de resultados</h4>
                            <h5 class="body__cuerpo-text-subtitle">Lunes a Viernes de 10:00 a 12:00 hs - 16:00 a 19:30 hs.</h5>
                        </div>
                    </div>

                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title"> • Horario de Visitas</h3>
                            <h5 class="body__cuerpo-text-subtitle">El Sanatorio Privado Río Cuarto implementa normas/políticas que regulan las visitas y el acompañamiento de los pacientes que permanecen internados en la institución, permitiendo la permanencia de acompañantes definiendo horarios de visita en cada sector, siempre sujetos a las necesidades tanto del paciente como del sector en el cual se encuentre internado.<br> Se transmite a la familia del paciente la información acerca de los horarios de visita correspondientes al sector mediante folletos, carteles informativos o verbalmente por parte del equipo tratante.<br> El régimen y el horario definido según el área de internación serán los siguientes: </h5>
                            <h4 class="body__cuerpo-text-title">Informe Médico:</h4>
                            <h5 class="body__cuerpo-text-subtitle">Terapia: 12:00 a 13:00 hs <br> Piso: 8:00 a 12:00 hs - Se le comunica al acompañante</h5>
                            <h4 class="body__cuerpo-text-title">Visitas:</h4>
                            <h5 class="body__cuerpo-text-subtitle">Piso: 17:00 a 19:00 hs</h5>
                            <h5 class="body__cuerpo-text-subtitle">Acompañantes: solo 1 (una) por persona a la vez. En el caso de habitaciones compartidas, el acompañante debe ser del mismo sexo que el paciente.<br> El cambio de acompañantes se deberá realizar en la sala de espera, no así en zona de tránsito de pacientes (pasillos). <br> Se solicita el retiro del acompañante ante la realización de un procedimiento o una situación de emergencia en el área.</h5>
                            <h4 class="body__cuerpo-text-title">Alimentación</h4>
                            <h5 class="body__cuerpo-text-subtitle">Desaryuno: 8:00 a 9:00 hs <br>Almuerzo: 12:00 a 13:00 hs <br>Merienda: 16:00 a 17:00 hs <br>Cena: 20:00 a 21:00 hs</h5>
                            <h4 class="body__cuerpo-text-title">Servicio de Limpieza de Habitaciones:</h4>
                            <h5 class="body__cuerpo-text-subtitle">Turno Mañana: 6:30 a 9:30 hs <br>Turno Tarde: 14:30 a 17:00 hs</h5>
                            <h5 class="body__cuerpo-text-subtitle">Aclaración: Fuera del horario establecido, se podrá solicitar el servicio, informando en sector cocina.<br> Por favor tenga en cuenta que, por políticas del Sanatorio, no está permitido fumar dentro de la institución y se prohíbe el ingreso de animales, armas de cualquier tipo, flores, equipo de mate, reposeras, etc.</h5>

                        </div>
                    </div>



                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .horarios-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .horarios-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 100px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
        }
        .body__cuerpo-text{
            margin: 0px auto;
            min-height: 80px;
            width: 90%;
        }
        .body__cuerpo-text-title{
            margin: 0px auto;
            font-size: 20px;
        }
        .body__cuerpo-text-subtitle{
            margin: 5px 0px 20px 0px;
            font-size: 15px;
        }
        .title{
            margin: 25px auto;
            text-align: center;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("horarios-page", HorariosPage)



