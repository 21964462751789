import { state } from "../state";

/* Variables */
const style = document.createElement('style');
const wallpaper = require("url:../assets/home.gif")

/* Class Welcome */
export class WelcomePage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()

        state.dataWelcome()

    };

    /* Render */
    render(){
        this.className = 'welcome-page';
        this.innerHTML = 
        `
        <!-- Container -->
        <div class="welcome-container">
            <!-- Body -->
            <div class="welcome-body">
                <img src="${wallpaper}" alt="sanatorio privado" class="wallpaper">
                <!-- <div class="body__carrusel"></div> -->
                <div class="body-box">
                    <div class="body__break"></div>
                    
                    <!-- <div class="body__formulario"> -->
                    <!--     <a class="formulario" href="https://forms.gle/1yDoAo8U9ZGRntNX8">Inscripciones para Concurso Residencias Médicas de Ortopedia y Traumatológia</a> -->
                    <!-- </div> -->
                    
                    <div class="body__break"></div>
                    
                    <div class="body__noticias">
                        <template class="noticias__template">
                            <div class="noticias">
                                <img class="noticias__photo" src="">
                                <div class="noticias__box">
                                    <h3 class="noticias__title"></h3>
                                    <h5 class="noticias__subtitle"></h5>
                                </div>
                            </div>
                        </template>
                    </div>
            
                    <div class="body__break"></div>
            
                    <div class="body__ubicacion">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title">Sede Central</h3>
                            <h5 class="body__cuerpo-text-subtitle right">Dirección: Bv. Roca 949 - Rio Cuarto <br> Lineas Rotativas: +549 358 4675800 <br> Contacto: info@sanatorioprivado.com.ar</h5>
                        </div>
                        <div class="body__cuerpo-map"> 
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13364.847665217008!2d-64.3399908!3d-33.1297968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d20007b4855555%3A0x148336ad0c4d8c8b!2sPrestaciones%20Integrales%20Medicas%20Sa!5e0!3m2!1ses!2sar!4v1699966049624!5m2!1ses!2sar" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>                    
                    </div>

                    <div class="body__break"></div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .welcome-container{
            max-width: 100%;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .welcome-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
            flex-direction: column;
            align-items: center;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__carrusel,
        .wallpaper{
            height: 500px;
            width: 1200px;
            background-color: #1E1E1E75;
        }
        .body__break{
            height: 35px;
        }
        .body__noticias{
            background-color: #1e1e1e10;
            min-height: 300px;
            width: 100%;
            display: grid;
            grid-template-columns: 33% 33% 33%;
            justify-content: center;
            padding: 25px;
            gap: 10px;
        }
        .body__formulario{
            background-color: #1e1e1e10;
            min-height: 100px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 23px;
        }
        .formulario{
            text-decoration: none;
        }
        .noticias{
            display: flex;
            flex-direction: column;
            min-height: 250px;
            align-items: center;
            gap: 20px;
        }
        .noticias__box{
            min-height: 150px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .noticias__title{
            margin: 0 auto;
            font-size: 25px;
            text-align: center;
        }
        .noticias__subtitle{
            font-size: 15px;
        }
        .noticias__photo{
            height: 200px;
            width: 100%;
            background-color: #1E1E1E75;
        }
        .body__ubicacion{
            background-color: #1e1e1e10;
            height: 300px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
        }
        .body__cuerpo-text{
            padding: 15px;
            margin: 20px auto;
        }
        .body__cuerpo-text-title{
            margin: 0 auto;
            font-size: 25px;
            text-align: center;
        }
        .body__cuerpo-text-subtitle{
            font-size: 15px;
        }
        .body__cuerpo-map{
            height: 300px;
            width: 60%;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("welcome-page", WelcomePage)