/* Variables */
const style = document.createElement('style');
const btn_email = require("url:../assets/btn-email.png")
const btn_whatsapp = require("url:../assets/btn-whatsapp.png")
const btn_horarios = require("url:../assets/btn-horarios.png")

/* Class Cardiologia */
export class CardiologiaPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()
    };

    /* Render */
    render(){
        this.className = 'cardiologia-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="cardiologia-container">
            <!-- Body -->
            <div class="cardiologia-body">
                <div class="body-box">
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <br>
                            <h3 class="body__cuerpo-text-title">Servicio de Cardiología</h3>
                            <br>
                            <h5 class="body__cuerpo-text-subtitle">En Sanatorio Privado Río Cuarto, entendemos la importancia de la salud cardíaca y nos enorgullece ofrecer un servicio de Cardiología de primer nivel con los mejores cardiólogos de la provincia a su disposición.<br>Nuestro equipo de Cardiología está compuesto por destacados profesionales, reconocidos como los mejores en su campo. Su dedicación y experiencia aseguran que cada paciente reciba una atención personalizada y de calidad excepcional.<br>Nos comprometemos a brindar las herramientas necesarias para evaluar su salud cardíaca de manera completa. Realizamos estudios de vanguardia, incluyendo Ecocardiograma Doppler, Ergometría, Holter de 24 horas y Presurometría o Monitoreo Ambulatorio de Presión Arterial. Estos estudios son fundamentales para un diagnóstico preciso y un plan de tratamiento efectivo.</h5>
                            <h3 class="body__cuerpo-text-estudio">ECOCARDIOGRAMA DOPPLER</h5>
                            <h5 class="body__cuerpo-text-subtitle">Permite visualizar las cavidades cardíacas, su tamaño, el movimiento de sus paredes, además de la estructura y el funcionamiento de las válvulas.</p>¿CÓMO FUNCIONA?<br>Las imágenes se obtienen apoyando un dispositivo llamado “transductor”, en distintos puntos de la pared del tórax. Se trata de un procedimiento no invasivo y no doloroso.</p>RECOMENDACIONES PARA EL PACIENTE<br>• No requiere preparación previa ni ayuno.<br>• Debe tomar su medicación habitual.</p>No requiere preparación</h5>
                            <h3 class="body__cuerpo-text-estudio">ERGOMETRÍA (BICICLETA)</h5>
                            <h5 class="body__cuerpo-text-subtitle">Se denomina ergometría a la prueba diagnóstica que consiste en realizar un registro del electrocardiograma durante un esfuerzo controlado, está indicado ante factores de riesgo tales como hipertensión, diabetes y referencia de dolor de pecho.</p>¿CÓMO FUNCIONA?<br>Se le colocan unos electrodos adhesivos en el tórax, se le conecta al equipo y siguiendo las instrucciones debe andar en bicicleta estática. Durante el esfuerzo se le registrará el electrocardiograma y se le tomará la presión arterial. El paciente debe indicar al personal médico, si presenta en la prueba, cualquier incidencia como (fatiga, cansancio, dolor en el pecho, palpitaciones, dificultad respiratoria).</p>RECOMENDACIONES PARA EL PACIENTE<br>• Ingerir un desayuno liviano previo al estudio.<br>• No fumar por lo menos 8 horas antes del estudio.<br>• Evitar la ingesta de bebidas alcohólicas, cafeína y otras excitantes horas antes a la realización de la prueba.<br>• Vaya equipado con ropa holgada y cómoda, y calzado apropiado (zapatillas de deporte o similar) para andar o correr.</p>Preparación:<br>• Traer 10 Electrodos.<br>• Llegar puntual a su turno.<br>• Desayunar liviano (sin excepción).<br>• Tomar la medicación habitual.<br>• Utilizar ropa cómoda y calzado adecuado para realizar ejercicio físico.</h5>
                            <h3 class="body__cuerpo-text-estudio">HOLTER DE 24 HORAS</h3>
                            <h5 class="body__cuerpo-text-subtitle">Consiste en la monitorización ambulatoria del electrocardiograma por un periodo de 24 a 48 horas, durante la actividad diaria habitual del paciente. Se usa en pacientes con sospecha de arritmia cardíaca.</p>¿CÓMO FUNCIONA?<br>El monitor tiene entre 6 y 10 cables. Las derivaciones van conectados a electrodos, los cuales se colocan en el pecho, estos se conectan a un Holter del tamaño de un teléfono móvil que graba los impulsos eléctricos del corazón durante 24 horas.</p>¿CUÁNDO ESTA INDICADA?<br>• Evaluación de síntomas que estén relacionados con ARRITMIAS.<br>• Detección, cuantificación y mecanismo de las arritmias.<br>• Valorar el riesgo en pacientes con arritmias sintomáticas y asintomáticas.<br>• Evaluación de la eficacia de la terapia antiarrítmica.<br>• Evaluación del funcionamiento de los marcapasos.</p>RECOMENDACIÓN PARA EL PACIENTE<br>• No se necesita estar en ayunas, Puede ingerir alimentos.<br>• El/La paciente debe venir bañado antes de colocarse el equipo porque durante el monitoreo no se puede bañar, hasta ser retirado.<br>• No debe untarse cremas, talcos, aceites, colonias, ya que se puede dañar el equipo.<br>• No es necesario suspender los medicamentos si toma algunos.</p>Preparación:<br>El paciente recibirá un equipo que deberá devolver luego de siete días hábiles<br>• Traer 8 Electrodos y 1 Pila “AA”.</h5>
                            <h3 class="body__cuerpo-text-estudio">PRESUROMETRÍA O MONITOREO AMBULATORIO DE PRESIÓN ARTERIAL</h3>
                            <h5 class="body__cuerpo-text-subtitle">La presurometría es un estudio indicado para el monitoreo de la presión arterial durante 24 hs. Se indica en pacientes con sospecha de hipertensión y en pacientes hipertensos para valoración de la respuesta al tratamiento.</p>¿CÓMO FUNCIONA?<br>El presurómetro se compone de un manguito de insuflación que se coloca en el brazo del paciente, una manguera con una válvula y un aparato de mediciones que se lleva colgado es un cinturón, las mediciones son en forma automática cada 30 a 60 minutos de acuerdo a programación.</p>RECOMENDACIONES PARA EL PACIENTE<br>• No se requiere preparación previa ni estar en ayunas.<br>• Durante el estudio el paciente no se puede bañar.<br>• Cuando el presurómetro esté realizando la medición el paciente debe mantener el brazo relajado y estirado hasta que la misma se complete.</p>Preparación:<br>• Traer 2 Pilas “AA”.</h5>
                            <br>
                            <h3 class="body__cuerpo-text-title">Servicio de Hemodinamia</h3>
                            <br>
                            <h5 class="body__cuerpo-text-subtitle">Además de nuestros estudios cardiológicos, contamos con el servicio de Hemodinamia, una herramienta invaluable para evaluar y tratar diversas afecciones cardíacas. Nuestro compromiso es poner la última tecnología médica a disposición y mejorar la calidad de vida de nuestros pacientes, especialmente aquellos que son adultos mayores. Reconocemos la importancia de brindar un cuidado especializado a esta población, ya que a menudo enfrentan problemas de salud relacionados con el sistema cardiovascular.<br>En nuestras modernas instalaciones, utilizamos tecnología de vanguardia para llevar a cabo procedimientos de hemodinamia. Esto nos permite diagnosticar y tratar de manera efectiva una amplia gama de enfermedades cardiovasculares, como enfermedad coronaria, insuficiencia cardíaca y enfermedad de las arterias periféricas.<br>Nuestro equipo médico está altamente comprometido con la excelencia y el cuidado integral del paciente. Trabajamos en estrecha colaboración con otros especialistas médicos para ofrecer un enfoque multidisciplinario en la atención de nuestros pacientes.<br></h5>
                            <br>
                            <h5 class="body__cuerpo-text-subtitle">• Conctacto:</h5>
                            <div class="body__cuerpo-contacto">
                                <a class="" href="/horarios"><img class="btn" src="${btn_horarios}"></a>
                                <a class="" href="https://api.whatsapp.com/send?phone=543585610243&text&source&data&app_absent&lang=es"><img class="btn" src="${btn_whatsapp}"></a>
                                <a class="" href="mailto:cardiologia@sanatorioprivado.com.ar"><img class="btn" src="${btn_email}"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .cardiologia-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .cardiologia-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 100px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
        }
        .body__cuerpo-text{
            min-height: 200px;
            width: 90%;
        }
        .body__cuerpo-text-title{
            margin: 10px auto;
            font-size: 25px;
            text-align: start;
        }
        .body__cuerpo-text-subtitle{
            margin: 10px auto;
            font-size: 15px;
        }
        .body__cuerpo-text-estudio{
            font-size: 20px;
            margin: 5px auto;
        }
        .btn{
            width: 150px;
        }
        .body__cuerpo-contacto{
            display: grid;
            grid-template-columns: 30% 30% 30%;
            justify-content: space-evenly;
            justify-items: center;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("cardiologia-page", CardiologiaPage)