/* Variables */
const style = document.createElement('style');
const whatsappIMG = require("url:../assets/icon-whatsapp.png")
const linkedinIMG = require("url:../assets/icon-linkedin.png")
const instagramIMG = require("url:../assets/icon-instagram.png")
const facebookIMG = require("url:../assets/icon-facebook.png")

/* Class Welcome */
export class FooterComponent extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()

    };

    /* Render */
    render(){
        this.className = 'footer';
        this.innerHTML = 
        `
        <!-- Footer -->
        <div class="footer">
            <div class="footer-box">
                <div class="footer__sucursales">
                    <h4 class="footer__sucursales-title">Sedes y Centros Asociados</h4>
                    <a href="/sedes">Sanatorio Privado Rio Cuarto</a>
                    <a href="/sedes">Bio Centro Médico</a>
                    <a href="/sedes">Resonancia Magnetica Rio Cuarto</a>
                </div>
                <div class="footer__informacion">
                    <h4  class="footer__informacion-title">Contacto</h4>
                    <!-- <a href="/">Dejanos un mensaje</a> -->
                    <a href="/">¿Donde nos encontramos?</a>
                    <a href="#">Ir al inicio</a>
                </div>
                <div class="footer__redes">
                    <h4 class="footer__redes-title">Redes Sociales</h4>
                    <div class="footer__redes-icon">
                        <a href="https://es-la.facebook.com/sanatorioprivadoderiocuarto/"><img src="${facebookIMG}" alt="facebook" class="footer__redes-img"></a>
                        <a href="https://www.linkedin.com/company/sanatorioprivado-rio-cuarto/"><img src="${linkedinIMG}" alt="linkedin" class="footer__redes-img"></a>
                        <a href="https://instagram.com/sanatorioprivadoriocuarto/"><img src="${instagramIMG}" alt="instagram" class="footer__redes-img"></a>
                        <a href="https://api.whatsapp.com/send?phone=543584675800&text&source&data&app_absent&lang=es"><img src="${whatsappIMG}" alt="whatsapp" class="footer__redes-img"></a>
                    </div>
                </div>
            </div>
            <div class="footer__desing">
                <h6 class="footer__desing-info">Sanatorio Privado 2024 ©</h6>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Footer */
        .footer{
            font-family: 'Poppins', sans-serif;
            width: 100%;
            min-height: 250px;
            background-color: #50af70;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
        }
        /* Footer - Box */
        .footer-box{
            width: 1024px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        .footer__sucursales,
        .footer__informacion,
        .footer__redes{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .footer__sucursales-title,
        .footer__informacion-title,
        .footer__redes-title{
            font-size: 18px;
            color: whitesmoke
        }
        .footer__sucursales a,
        .footer__informacion a{
            font-size: 15px;
            color: rgba(245, 245, 245, 0.72);
            padding: 3px;
            text-decoration: none;
        }
        .footer__redes-icon{
            display: flex;
            gap: 10px;
        }
        .footer__redes-img{
            height: 35px;
            width: 35px;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("footer-components", FooterComponent)