import { state } from "../state"
import { data } from "../ooss"

/* Variables */
const style = document.createElement('style');

/* Class Cobertura */
export class CoberturaPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()
        
        state.dataMutuales(data)
    };

    /* Render */
    render(){
        this.className = 'cobertura-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="cobertura-container">
            <!-- Body -->
            <div class="cobertura-body">
                <div class="body-box">

                    <div class="body__cuerpo info">
                        <div class="body__cuerpo-text info">
                            <h3 class="body__cuerpo-text-title">Obras Sociales</h3>
                            <h5 class="body__cuerpo-text-subtitle">El Sanatorio Privado Río Cuarto atiende a las siguientes obras sociales y prepagas. Para conocer las modalidades de atención de la cobertura médica que poseas, deberás consultar a la misma dónde te informarán las condiciones para atenderte en nuestra Institución.</h5>
                        </div>
                    </div>

                    <div class="body__mutuales-container">
                        <template class="mutuales__template">
                            <div class="mutuales__container">
                                <h3 class="mutuales__info">Mutual</h3>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .cobertura-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .cobertura-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__mutuales-container{
            background-color: #1e1e1e10;
            min-height: 250px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            padding: 20px;
        }
        .mutuales__container{
            height: 35
            px;
            width: 85%;
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .mutuales__info{
            text-align: start;
            margin: 0;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 250px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
        }
        .body__cuerpo-text{
            margin: 20px 0px;
            min-height: 200px;
            width: 90%;
            text-align: center;
            gap: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        .body__cuerpo-text-title{
            margin: 0 auto;
            font-size: 25px;
            text-align: center;
        }
        .body__cuerpo-text-link{
            font-size: 18px;
            border-radius: 5px;
            text-decoration: none;
            color: whitesmoke;
            background-color: #2f6b31;
        }
        .body__cuerpo-text-subtitle{
            font-size: 15px;
            margin: 0px auto;
            text-align: start;
            width: 90%;
        }
        .info{
            min-height: 100px;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("cobertura-page", CoberturaPage)