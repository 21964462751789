/* Variables */

/* State */
const state = {

    /* Inicio */
    init(){},

    /* Template - Foto */
    searchImagen(idPhoto, data){
        const i = data.includes.Asset.find((item) => {
            return item.sys.id == idPhoto;
        })
        return i;
    },

    /* Template - Welcome (Template) */
    addNotice(params){
        const template: any = document.querySelector(".noticias__template");
        const container = document.querySelector(".body__noticias");
        template.content.querySelector(".noticias__title").textContent = params.titulo;
        template.content.querySelector(".noticias__subtitle").textContent = params.informacion;
        template.content.querySelector(".noticias__photo").src = params.foto;
        const clone = document.importNode(template.content, true);
        container.appendChild(clone);
    },
    /* Template - Welcome (Fetch) */
    getNotice(){
        return fetch("https://cdn.contentful.com/spaces/vzrchh5059a8/environments/master/entries?access_token=rycvlQrg1NFTKqUz7jfatycdJ9nuFrsxh8LlfViD9so&content_type=home")
        .then((r) => {
            return r.json();
        })
        .then((data) => {
            const notice = data.items.map((item) => {
                return {
                    titulo: item.fields.titulo,
                    informacion: item.fields.informacion,
                    foto: this.searchImagen(item.fields.foto.sys.id, data).fields.file.url,
                }
            })
            return notice;
        })
    },
    /* Template - Welcome (Publica) */
    dataWelcome(){
        this.getNotice()
        .then((item) => {
            for (const i of item) {
                this.addNotice(i)
            }
        })
    },

    /* Template - Trabaja (Template) */
    addJob(params){
        const template: any = document.querySelector(".job__template");
        const container = document.querySelector(".job__container");
        template.content.querySelector(".job__titulo").textContent = params.puesto;
        template.content.querySelector(".job__descripcion").textContent = params.descripcion;
        template.content.querySelector(".job__disponibilidad").textContent = params.disponibilidad;
        const clone = document.importNode(template.content, true);
        container.appendChild(clone);
    },
    /* Template - Trabaja (Fetch) */
    getJob(){
        return fetch("https://cdn.contentful.com/spaces/vzrchh5059a8/environments/master/entries?access_token=rycvlQrg1NFTKqUz7jfatycdJ9nuFrsxh8LlfViD9so&content_type=busquedas")
        .then((r) => {
            return r.json();
        })
        .then((data) => {
            const job = data.items.map((item) => {
                return {
                    puesto: item.fields.puesto,
                    descripcion: item.fields.descripcion,
                    disponibilidad: item.fields.disponibilidad,
                }
            })
            return job;
        }) 
    },
    /* Template - Trabaja (Publica) */
    dataJob(){
        this.getJob()
        .then((item) => {
            for (const i of item) {
                this.addJob(i)
            }
        })
    },

    /* Template - Sedes (Template) */
    addSedes(params){
        const template: any = document.querySelector(".sedes__template");
        const container = document.querySelector(".sedes__container");
        template.content.querySelector(".sedes__titulo").textContent = params.periferico;
        template.content.querySelector(".sedes__direccion").textContent = params.direccion;
        template.content.querySelector(".sedes__phone").textContent = params.telefono;
        template.content.querySelector(".sedes__mail").textContent = params.contacto;
        template.content.querySelector(".sedes__photo").src = params.foto;
        const clone = document.importNode(template.content, true);
        container.appendChild(clone);
    },
    /* Template - Sedes (Fetch) */
    getSedes(){
        return fetch("https://cdn.contentful.com/spaces/vzrchh5059a8/environments/master/entries?access_token=rycvlQrg1NFTKqUz7jfatycdJ9nuFrsxh8LlfViD9so&content_type=sucursales")
        .then((r) => {
            return r.json();
        })
        .then((data) => {
            const sedes = data.items.map((item) => {
                return {
                    periferico: item.fields.periferico,
                    direccion: item.fields.direccion,
                    telefono: item.fields.telefono,
                    contacto: item.fields.contacto,
                    foto: this.searchImagen(item.fields.foto.sys.id, data).fields.file.url,
                }
            })
            return sedes;
        }) 
    },
    /* Template - Sedes (Publica) */
    dataSedes(){
        this.getSedes()
        .then((item) => {
            for (const i of item) {
                this.addSedes(i)
            }
        })
    },

    /* Template - Profesionales (Fetch) */
    addProfesionales(params){    
        const template: any = document.querySelector(".profesional__template");
        const container = document.querySelector(".body__profesionales-container");
        template.content.querySelector(".profesional__especialidad").textContent = params.Especialidad;
        template.content.querySelector(".profesional__info").textContent = params.Profesional;
        const clone = document.importNode(template.content, true);
        container.appendChild(clone);
    },
    /* Template - Profesionales (Publica) */
    dataProfesionales(data){
        for (const i of data) {
            this.addProfesionales(i)
        }
    },

    /* Template - Mutuales (Fetch) */
    addMutuales(params){    
        const template: any = document.querySelector(".mutuales__template");
        const container = document.querySelector(".body__mutuales-container");
        template.content.querySelector(".mutuales__info").textContent = params.Mutual;
        const clone = document.importNode(template.content, true);
        container.appendChild(clone);
    },
    /* Template - Mutuales (Publica) */
    dataMutuales(data){
        for (const i of data) {
            this.addMutuales(i)
        }
    },

};

/* Export */
export { state };