export const data = [
    {
        Especialidad: "Alergia",
        Profesional: ["Fernandez, Alicia", "Yammal, Garbiela"],
    },
    {
        Especialidad: "Anestesiología",
        Profesional: ["Marazano, Antonella", "Padilla, Natalia"],
    },
    {
        Especialidad: "Cardiologia",
        Profesional: [
            "Achilli, Federico",
            "Ferrero, Daniel Omar",
            "Alvez, Antonio Joaquin",
            "Espilocin, Maria Gisela",
            "Bovio, Monica",
            "Fernandez Pinto, Edwin",
        ],
    },
    {
        Especialidad: "Cardiologia Infantil",
        Profesional: ["ZANOTTO, Isabel"],
    },
    {
        Especialidad: "Cirugía Cardíaca",
        Profesional: ["CARDINALI, Eduardo Luis"],
    },
    {
        Especialidad: "Cirugía General y Gastroenterología",
        Profesional: ["PEZZUTTI, Hernan", "PEZZUTTI, Leonardo"],
    },
    {
        Especialidad: "Cirugía Torax",
        Profesional: ["ABDALA, Victor Esteban"],
    },
    {
        Especialidad: "Clínica Médica",
        Profesional: [
            "BLANCO, Gabriela Silvana",
            "PAREDES, Marcos Andres",
            "RODRIGUEZ TELLO, Luis",
            "SORIA, Pablo",
        ],
    },
    {
        Especialidad: "Colposcopía",
        Profesional: ["TORRES, Carolina Etel"],
    },
    {
        Especialidad: "Dermatología ",
        Profesional: ["SARDOY, Agustina"],
    },
    {
        Especialidad: "Diabetelogía",
        Profesional: ["AYALA, Mariela Andrea"],
    },
    {
        Especialidad: "Endocrinología",
        Profesional: ["ARCE JAURY, Alejandra"],
    },
    {
        Especialidad: "Flebologia",
        Profesional: ["PEZZUTTI, Hernán ", "GUGLIELMONE, Daniel"],
    },
    {
        Especialidad: "Fonoaudiología",
        Profesional: ["PRIMO, Claudina"],
    },
    {
        Especialidad: "Gastroenterología",
        Profesional: ["FERRARIO, Marcelo", "BALDUZZI, Carolina"],
    },
    {
        Especialidad: "Gastroenterología Infantil",
        Profesional: ["MOYANO, Damaris Magali", "COMPAGNUCCI, Horacio Andres"],
    },
    {
        Especialidad: "Ginecología y Obstetricia",
        Profesional: [
            "BARBERO, Ezequiel",
            "BERAUDO, Gustavo Marcelo",
            "CARRASCOSA, Carolina",
            "DEPAULI, Sofia",
        ],
    },
    {
        Especialidad: "Hepatología",
        Profesional: ["MENDOZA, Carlos"],
    },
    {
        Especialidad: "Infectologia",
        Profesional: ["ALANIZ, Valeria Mariana"],
    },
    {
        Especialidad: "Kinesiología y Fisioterapia",
        Profesional: ["ESPINOSA, Florencia", "PAZ, juan"],
    },
    {
        Especialidad: "Mastología",
        Profesional: ["DI GIORGIO, Roberto"],
    },
    {
        Especialidad: "Nefrología Adulto",
        Profesional: ["ABASCAL, Juan Manuel"],
    },
    {
        Especialidad: "Neumonología infantil",
        Profesional: ["FERREYRA, Clarisa del Carmen"],
    },
    {
        Especialidad: "Neurocirugía",
        Profesional: "ETCHECHOURY, Juan Manuel",
    },
    {
        Especialidad: "Neurología",
        Profesional: ["RIERA, Juan Manuel", "TORRIGIANI, Enzo Alejandro"],
    },
    {
        Especialidad: "Neurologia Infantil",
        Profesional: "MATHIEU, Maria Lidia",
    },
    {
        Especialidad: "Nutrición",
        Profesional: ["MAITANA, Maria Lucia", "TALANO, Sebastian"],
    },
    {
        Especialidad: "Oftalmología",
        Profesional: ["FARINA, Maria Fernanda", "LAFUENTE, Cecilia"],
    },
    {
        Especialidad: "Oncología - Hematología",
        Profesional: ["QUINTANA, Sebastián", "PAGOLA, Natalia Ruth"],
    },
    {
        Especialidad: "Otorrinolaringología",
        Profesional: ["COLOMBANO, Pablo"],
    },
    {
        Especialidad: "Pediatría",
        Profesional: [
            "BARRIONUEVO, Patricia",
            "COMPAGNUCCI, Horacio Andres",
            "SERRANO, Héctor Tomás",
        ],
    },
    {
        Especialidad: "Psicología",
        Profesional: ["FENOGLIO, Melanie", "RAMACCIOTTI, Sabrina"],
    },
    {
        Especialidad: "Psiquiatría",
        Profesional: ["RODRIGUEZ, Silvia Susana"],
    },
    {
        Especialidad: "Reumatologia",
        Profesional: ["PICCO, Luis"],
    },
    {
        Especialidad: "Traumatología",
        Profesional: ["AGUERO GIODA, Carlos Alfredo",
            "AGUERO GIODA, Fernando",
            "BALMACEDA, Hermes Ariel",
            "CAPELL, Federico Luis",
            "VIGLIONE, Guillermo",
            "LOVATO, Mauro Atilio",
            "MACARRON, Marcelo",
            "SCARMAGNANI, Miguel",
            "MANFREDINI, Jose",
        ],
    },
    {
        Especialidad: "Urología",
        Profesional: ["PUELLES, Marcos Agustin", "QUIROGA, Sebastian Andres"],
    },
    {
        Especialidad: "Vascular Periferica",
        Profesional: ["GARELLI, Guillermo", "FISCHER, Raul Walter"],
    },
    {
        Especialidad: "Videoendoscopía y Gastroenterología",
        Profesional: ["MENDOZA, Carlos", "BALDUZZI, Carolina"],
    },
];
