/* Imports */
import "./components/header"
import "./components/footer"
import "./views/no-page"
import "./views/welcome"
import "./views/nosotros"
import "./views/sedes"
import "./views/cobertura"
import "./views/horarios"
import "./views/.profesionales"
import "./views/trabaja"
import "./views/diagnostico"
import "./views/laboratorio"
import "./views/cardiologia"
import "./router"
import { state } from "./state";

/* Function */
(() => {
    state.init();
})();