import { state } from "../state";

/* Variables */
const style = document.createElement('style');

/* Class Trabaja */
export class TrabajaPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()

        state.dataJob()
    };

    /* Render */
    render(){
        this.className = 'trabaja-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="trabaja-container">
            <!-- Body -->
            <div class="trabaja-body">
                <div class="body-box">
                    <div class="body__cuerpo info">
                        <div class="body__cuerpo-text info">
                            <h3 class="body__cuerpo-text-title">¡Estas son nuestras búsquedas activas!</h3>
                            <a class="formulario__cv" href="https://forms.gle/1yDoAo8U9ZGRntNX8">Carga tu CV</a>
                        </div>
                    </div>
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text  job__container">
                            <template class="job__template">
                                <div class="job__box">
                                    <h2 class="job__titulo">Puesto Solicitado</h2>
                                    <div class="job__etiquetas">
                                        <h2 class="job__disponibilidad">Disponibilidad</h2>
                                    </div>
                                    <p class="job__descripcion">Descripcion del puesto buscado. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis aliquid cum possimus veritatis dicta quo quas dolores labore in inventore natus suscipit voluptatum qui consequatur, sequi debitis, odio, architecto beatae?</p>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="body__cuerpo info">
                        <div class="body__enviar">
                            <h3 class="enviar">¿Donde puedo enviar mi CV?</h3>
                            <h5 class="enviar-info">Para enviar CV, hacerlo a seleccion@sanatorioprivado.com.ar e indicar el puesto de interés en el asunto del mail.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .trabaja-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .trabaja-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
        }
        .body__cuerpo-text{
            margin: 20px 0px 0px 0px;
            width: 60%;
            text-align: center;
            gap: 20px;
            display: flex;
            <!-- flex-direction: column; -->
            align-items: center;
            justify-content: flex-start;
        }
        .formulario__cv{
            text-decoration: none;
            width: 30%;
            color: whitesmoke;
            background-color: #50af70;
            border-radius: 5px;
            padding: 7px;
        }
        .body__cuerpo-text-title{
            margin: 0 auto;
            font-size: 21px;
            text-align: center;
        }
        .body__enviar{
            width: 90%;
            margin: 0px;
            margin-left: 80px;
        }
        .enviar{
            margin: 0px auto;
            font-size: 21px;
        }
        .enviar-info{
            margin: 10px auto 25px auto;
            width: 90%;
        }
        .body__cuerpo-text-link{
            font-size: 18px;
            border-radius: 5px;
            text-decoration: none;
            color: whitesmoke;
            background-color: #50af70;
            padding: 7px 250px;
            transition: 0.5s;
        }
        .body__cuerpo-text-link:hover{
            background-color: #4CAF50;
            transition: 0.5s;
        }
        .job__container{
            display: flex;
            gap: 50px;
            margin: 30px auto;
            align-items: flex-start;
        }
        .job__box{
            text-align: start;
            border-left: solid 3px green;
            padding: 10px 10px 10px 20px;
            transition: 0.3s;
            min-height: 100%;
            width: 100%;
        }
        .job__box:hover{
            transition: 0.3s;
            border-left: solid 7px green;
            background: #306c3129;
        }
        .job__titulo{
            font-size: 25px;
            margin: 0px auto;
        }
        .job__descripcion{
            font-style: italic;
            margin: 0px auto;
        }
        .job__disponibilidad,
        .job__auxiliar{
            font-size: 14px;
            background-color: lightgrey;
            padding: 0px 10px;
            width: fit-content;
            border-radius: 50px;
        }
        .job__photo{
            height: 200px;
            width: 100%;
            background-color: #1E1E1E75;
        }
        .job__etiquetas{
            display: flex;
            gap: 10px;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("trabaja-page", TrabajaPage)