import { state } from "../state";
import { data } from "../.Prueba-Profesionales"

/* Variables */
const style = document.createElement('style');

/* Class Profesionales */
export class ProfesionalesPage extends HTMLElement {

    /* Connected to CallBack */
    connectedCallback(){
        this.render()
                
        state.dataProfesionales(data)

    };

    /* Render */
    render(){
        this.className = 'profesionales-page';
        this.innerHTML =
        `
        <!-- Page -->
        <div class="profesionales-container">
            <!-- Body -->
            <div class="profesionales-body">
                <div class="body-box">
                    
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text no-photo">
                            <h3 class="body__cuerpo-text-title">Profesionales que nos acompañan</h3>
                        </div>
                    </div>

                    <div class="body__profesionales-container">
                        <template class="profesional__template">
                            <div class="profesional__container">
                                <h3 class="profesional__especialidad">Especialidad</h3>
                                <p class="profesional__info">Dr. Apellido, Nombre</p>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>
        `
        style.innerHTML =
        `
        /* Page */
        .profesionales-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        /* Body */
        .profesionales-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__profesionales-container{
            background-color: #1e1e1e10;
            min-height: 250px;
            width: 100%;
            display: flex;
            justify-items: center;
            gap: 5px;
            padding: 20px;
            flex-direction: column;
        }
        .profesional__container{
            height: 35px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            transition: 0.3s;
            padding: 0px 20px; 
        }
        .profesional__container:hover{
            background-color: #4CAF50;
            font-size: 130%;
            transition: 0.3s;
            color: beige;
        }
        .profesional__info,
        .profesional__especialidad{
            text-align: center;
            margin: 0;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 100px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
        }
        .body__cuerpo-text{
            height: 100px;
            width: 400px;
        }
        .body__cuerpo-text-title{
            margin: 0 auto;
            font-size: 25px;
            text-align: center;
        }
        .body__cuerpo-text-subtitle{
            font-size: 15px;
            margin: 0 auto;
        }
        .body__cuerpo-img{
            height: 200px;
            width: 400px;
            background-color: #1E1E1E75;
        }
        .no-photo{
            width: 80%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .option__box{
            background-color: #1e1e1e10;
            min-height: 50px;
            width: 100%;
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
        }
        .option__title{
            font-size: 15px;
        }
        .option__seleccion{
            min-width: 400px;
            height: 30px;
            font-size: 15px;
            background: whitesmoke;
            border: none;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("profesionales-page", ProfesionalesPage)