/* Variables */
const style = document.createElement('style');
const siteIMG = require("url:../assets/site-construccion.png")

/* Class No Page */
export class NoPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()
    };

    /* Render */
    render(){
        this.className = 'no-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="no-page-container">
            <!-- Body -->
            <div class="no-page-body">
                <div class="body-box">
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text">
                            <h3 class="body__cuerpo-text-title">Sitio en construcción.</h3>
                            <h3 class="body__cuerpo-text-subtitle">Proximamente mas información.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .no-page-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        /* Body */
        .no-page-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            height: 350px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        .body__cuerpo-text{
            height: 300px;
            width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 50px;
        }
        .body__cuerpo-text-title{
            margin: 0px;
            font-size: 25px;
            text-align: center;
        }
        .body__cuerpo-text-subtitle{
            margin: 0px;
            font-size: 15px;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("no-page", NoPage)