/* Imports */
import { Router } from "@vaadin/router";

/* Variables */
const style = document.createElement('style');
const prequirurgico = require("url:../assets/prequirúrgico.pdf")
const logoSanatorio = require("url:../assets/logo-sprc.png")

/* Class nosotroslcome */
export class HeaderComponent extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()

        /* Button Header */
        const inicio:any = this.querySelector(".logo")
        inicio.addEventListener("click", ()=>{
            Router.go("/")
        })

        /* Button Nav - Nosotros */
        const institucional:any = this.querySelector(".institucional")
        institucional.addEventListener("click", ()=>{
            Router.go("/nosotros")
        })      
        
        const trabaja:any = this.querySelector(".trabaja")
        trabaja.addEventListener("click", ()=>{
            Router.go("/trabaja")
        })

        const cobertura:any = this.querySelector(".cobertura")
        cobertura.addEventListener("click", ()=>{
            Router.go("/cobertura")
        })
        
        const horarios:any = this.querySelector(".horarios")
        horarios.addEventListener("click", ()=>{
            Router.go("/horarios")
        })
    
        const profesionales:any = this.querySelector(".profesionales")
        profesionales.addEventListener("click", ()=>{
            Router.go("/profesionales")
        })      

        const diagnostico:any = this.querySelector(".diagnostico")
        diagnostico.addEventListener("click", ()=>{
            Router.go("/diagnostico")
        })      

        const laboratorio:any = this.querySelector(".laboratorio")
        laboratorio.addEventListener("click", ()=>{
            Router.go("/laboratorio")
        })      

        const cardiologia:any = this.querySelector(".cardiologia")
        cardiologia.addEventListener("click", ()=>{
            Router.go("/cardiologia")
        })      

        const sedes:any = this.querySelector(".sedes")
        sedes.addEventListener("click", ()=>{
            Router.go("/sedes")
        })      

    };

    /* Render */
    render(){
        this.className = 'header';
        this.innerHTML = 
        `
        <!-- Header -->
        <div class="header-container">
            <div class="header-box">
                <div class="header__logo-box">
                    <img src="${logoSanatorio}" class="header__logo logo">
                </div>

                <div class="header__link-box">
                    <h1 class="header__call-title">TURNOS</h1>
                    <a class="header__call-number" href="https://api.whatsapp.com/send?phone=543584675800&text&source&data&app_absent&lang=es">358 4675800</a>
                </div>

            </div>
        </div>
        <!-- Nav -->
        <div class="nav-container">
            <div class="nav-box">
                <div class="nav__nosotros-box">
                    <button class="nav__menu nosotros">NOSOTROS</button>
                    <div class="nav__nosotros-content">
                        <button class="nav__nosotros institucional">Institucional</button>
                        <button class="nav__nosotros trabaja">Trabaja con Nosotros</button>
                    </div>
                </div>
                <div class="nav__pacientes-box">
                    <button class="nav__menu pacientes">PACIENTES</button>
                    <div class="nav__pacientes-content">
                        <button class="nav__pacientes cobertura">Cobertura Médica</button>
                        <button class="nav__pacientes horarios">Horarios</button>
                        <a class="nav__pacientes internacion" href="${prequirurgico}" download="">Información para Internación</a>
                        <a class="nav__intranet" href="https://sprioiv.informemedico.com.ar/pacientes/">Entrega Digital - Sanatorio</a>
                        <a class="nav__intranet" href="https://rmrioiv.informemedico.com.ar/pacientes/">Entrega Digital - Resonador</a>
                    </div>
                </div>
                <div class="nav__servicios-box">
                    <button class="nav__menu servicios">SERVICIOS</button>
                    <div class="nav__servicios-content">
                        <button class="nav__servicios profesionales">Profesionales</button>
                        <button class="nav__servicios diagnostico">Diagnóstico por Imágenes</button>
                        <button class="nav__servicios laboratorio">Laboratorio de Análisis Clínicos y Microbiológicos</button>
                        <button class="nav__servicios cardiologia">Cardiología</button>
                        <button class="nav__servicios sedes">Sedes y Centros Asociados</button>
                    </div>
                </div>
                <div class="nav__intranet-box">
                    <button class="nav__menu intranet">INTRANET</button>
                    <div class="nav__intranet-content">
                        <a class="nav__intranet" href="http://200.123.248.6:10500/sistema/">Sistema</a>
                        <!-- <a class="nav__intranet" href="http://190.190.254.23:10500/sistema/">Sistema</a> -->
                        <a class="nav__intranet" href="http://webmail.sanatorioprivado.com.ar/">Webmail</a>
                        <a class="nav__intranet" href="https://portal.informemedico.com.ar/login.html">Portal Médico</a>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Header */
        .header-container{
            font-family: 'Poppins', sans-serif;
            height: 100px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        /* Header - Box */
        .header-box{
            width: 1024px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
        }
        .logo{
            height: 70px;
        }
        .header__link-box{
            display: flex;
            flex-direction: column;
        }
        .header__call-title{
            margin: 0 auto;
            font-size: 24px;
        }
        .header__call-number{
            margin: 0 auto;
            width: 200px;
            text-align: center;
            background-color: #50af70;
            color: whitesmoke;
            border-radius: 10px;
            font-size: 24px;
            text-decoration: none;
        }

        /* Nav */
        .nav-container{
            height: 50px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            background-color: whitesmoke;
        }
        /* Nav - Box */
        .nav-box{
            width: 768px;
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            justify-content: center;
        }
        .nav__menu{
            border: none;
            height: 50px;
            width: 150px;
            background-color: transparent;
            color: #1e1e1ead;
            text-align: center;
            font-size: 15px;
        }
        .nav__nosotros-box,
        .nav__pacientes-box,
        .nav__servicios-box,
        .nav__intranet-box{
            position: relative;
            display: inline-block;
        } 
        .nav__nosotros-content,
        .nav__pacientes-content,
        .nav__servicios-content,
        .nav__intranet-content{
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 260px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
        }
        .nav__nosotros,
        .nav__pacientes,
        .nav__servicios,
        .nav__intranet{
            border: none;
            min-height: 50px;
            min-width: 260px;
            background-color: transparent;
            color: rgba(0, 0, 0, 0.60);
            text-align: start;
            font-size: 15px;
            padding: 12px 16px;
        }
        .nav__nosotros-content a,
        .nav__pacientes-content a,
        .nav__servicios-content a,
        .nav__intranet-content a{
            color: rgba(0, 0, 0, 0.60);
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }
        .nosotros:hover,
        .nav__nosotros:hover,
        .pacientes:hover,
        .nav__pacientes:hover,
        .servicios:hover,
        .nav__servicios:hover,
        .intranet:hover,
        .nav__intranet:hover{
            transition: 0.5s;
            background-color: #ddd;
        }  
        .nav__nosotros-box:hover .nav__nosotros-content,
        .nav__pacientes-box:hover .nav__pacientes-content,
        .nav__servicios-box:hover .nav__servicios-content,
        .nav__intranet-box:hover .nav__intranet-content{
            transition: 0.5s;
            display: block;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("header-components", HeaderComponent)