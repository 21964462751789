import { state } from "../state";

/* Variables */
const style = document.createElement('style');

/* Class Sedes */
export class SedesPage extends HTMLElement {
    
    /* Connected to CallBack */
    connectedCallback(){
        this.render()

        state.dataSedes()
    };

    /* Render */
    render(){
        this.className = 'sedes-page';
        this.innerHTML = 
        `
        <!-- Page -->
        <div class="sedes-container">
            <!-- Body -->
            <div class="sedes-body">
                <div class="body-box">
                    <div class="body__cuerpo info">
                        <div class="body__cuerpo-text info">
                            <h3 class="body__cuerpo-text-title">Sedes y Centro Asociados</h3>
                        </div>
                    </div>
                    <div class="body__cuerpo">
                        <div class="body__cuerpo-text  sedes__container">
                            <template class="sedes__template">
                                <div class="sedes__box">
                                    <img class="sedes__photo" src="">
                                    <h2 class="sedes__titulo">Data</h2>
                                    <p class="sedes__direccion">Data</p>
                                    <p class="sedes__phone">Data</p>
                                    <p class="sedes__mail">Data</p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
        style.innerHTML = 
        `
        /* Page */
        .sedes-container{
            max-width: 100%;
            min-height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        /* Body */
        .sedes-body{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: whitesmoke;
        }
        /* Body - Box */
        .body-box{
            width: 1024px;
        }
        .body__cuerpo{
            background-color: #1e1e1e10;
            min-height: 250px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
        }
        .body__cuerpo-text{
            margin: 20px 0px;
            min-height: 200px;
            width: 90%;
            text-align: center;
            gap: 20px;
            
        }
        .body__cuerpo-text-title{
            margin: 0 auto;
            font-size: 25px;
            text-align: center;
        }
        .body__cuerpo-text-link{
            font-size: 18px;
            border-radius: 5px;
            text-decoration: none;
            color: whitesmoke;
            background-color: #2f6b31;
            padding: 7px 250px;
        }
        .info{
            min-height: auto;
        }
        .sedes__container{
            display: grid;
            grid-template-columns: 45% 45%;
            align-items: start;
            justify-content: center;
        }
        .sedes__box{
            text-align: start;
        }
        .sedes__titulo{
            font-size: 20px;
            margin: 0px auto;
        }
        .sedes__direccion{
            margin: 0px auto;
        }
        .sedes__phone{
            margin: 0px auto;
        }
        .sedes__mail{
            margin: 0px auto;
        }
        .sedes__photo{
            height: 200px;
            width: 100%;
            background-color: #1E1E1E75;
        }
        `
        this.appendChild(style)
    }
}

/* Define */
customElements.define("sedes-page", SedesPage)