/* Imports */
import { Router } from "@vaadin/router";

/* Variables */
const router = new Router(document.querySelector('.root'));

/* Function */
router.setRoutes([
    {path: '/', component: 'welcome-page'}, //Pagina de Inicio
    {path: '/nosotros', component: 'nosotros-page'}, //Pagina de Nosotros
    {path: '/trabaja', component: 'trabaja-page'}, //Pagina Trabaja con Nosotros
    {path: '/cobertura', component: 'cobertura-page'}, //Pagina de Cobertura Medica
    {path: '/horarios', component: 'horarios-page'}, //Pagina de Horarios
    {path: '/profesionales', component: 'no-page'}, //Pagina de Profesionales
    {path: '/diagnostico', component: 'diagnostico-page'}, //Pagina de Diagnostico
    {path: '/laboratorio', component: 'laboratorio-page'}, //Pagina de Laboratorio
    {path: '/cardiologia', component: 'cardiologia-page'}, //Pagina de Hemodinamia
    {path: '/sedes', component: 'sedes-page'}, //Pagina de Sedes y Centros Asociados
]);